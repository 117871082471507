const Noodl = require('@noodl/noodl-sdk');

const FormattedTextInput = Noodl.defineNode({
    name: 'FormattedTextInput',
    category: 'UI',
    color: 'green',
    inputs: {
        initialText: { type: 'string', displayName: 'Initial Text' },
    },
    outputs: {
        formattedText: { type: 'string', displayName: 'Formatted Text' },
    },
    setup(context) {
        const container = document.createElement('div');
        container.innerHTML = `
            <div class="formatted-text-input">
                <div class="toolbar">
                    <button onclick="document.execCommand('bold', false, null)">Bold</button>
                    <button onclick="document.execCommand('italic', false, null)">Italic</button>
                    <button onclick="document.execCommand('underline', false, null)">Underline</button>
                </div>
                <div class="input-area" contenteditable="true" oninput="updateOutput()"></div>
            </div>
        `;

        context.mount(container);

        const inputArea = container.querySelector('.input-area');
        inputArea.addEventListener('input', () => {
            context.setOutputs({ formattedText: inputArea.innerHTML });
        });
    },
});

module.exports = {
    nodes: [FormattedTextInput]
};
